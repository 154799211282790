import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import MockService from "@/core/mock/mock.service";
import {VERIFY_AUTH} from "@/core/services/store/auth.module";
import {RESET_LAYOUT_CONFIG} from "@/core/services/store/config.module";
import CKEditor from "ckeditor4-vue";
/*import CKEditor from '@ckeditor/ckeditor5-vue2';*/

Vue.use(CKEditor);
Vue.config.productionTip = false;
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)
import DatetimePicker from 'vuetify-datetime-picker'
// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'

Vue.use(DatetimePicker)
// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";


window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";

window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/inline-svg";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import Snotify from 'vue-snotify';
import Vuelidate from "vuelidate/src";
import VueConfirmDialog from 'vue-confirm-dialog'
import VueBus from 'vue-bus';
import GeneralMixin from "@/mixins/GeneralMixin";
// import RouterTab and styles
import RouterTab from 'vue-router-tab'
import 'vue-router-tab/dist/lib/vue-router-tab.css'
// Light Box
import VueLazyLoad from "vue-lazyload";
import LightBox from "vue-image-lightbox";
import "vue-image-lightbox/dist/vue-image-lightbox.min.css";
Vue.use(VueLazyLoad);
Vue.component("light-box", LightBox);

Vue.use(VueBus);

// API service init
ApiService.init();


Vue.use(Snotify);
Vue.use(VueConfirmDialog);


Vue.use(Vuelidate);

Vue.use(RouterTab)


Vue.use(GeneralMixin)
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
// Remove this to disable mock API
//MockService.init();

//Countdown
import VueCountdown from '@chenfengyuan/vue-countdown';
Vue.component(VueCountdown.name, VueCountdown);

/*Vue Copy*/
import ClickCopy from 'vue-directive-copy'
Vue.use(ClickCopy);

// Date TimePicker
import DateTimePicker from 'vue-vanilla-datetime-picker';
Vue.component('date-time-picker', DateTimePicker);
import "vue-vanilla-datetime-picker/dist/DateTimePicker.css";
//Moment
import VueMoment from 'vue-moment'
Vue.use(VueMoment);


import "vue-swatches/dist/vue-swatches.css"

router.beforeEach((to, from, next) => {

    // Ensure we checked auth before each page load.
    if (to.name != 'login' && to.name != 'logout' && to.name != 'register' && to.name != 'reset-password' && to.name != 'verification' && to.name != 'forgot-password') {
        Promise.all([store.dispatch(VERIFY_AUTH, to)]).then(next);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    } else {
        // console.log(to.name)
        Promise.all([to]).then(next);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }

    // reset config to initial state
    store.dispatch(RESET_LAYOUT_CONFIG);

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});


if (!window.localStorage.getItem('XSRF-TOKEN')) {
    Vue.axios.get("/sanctum/csrf-cookie").then(() => {

    });
}


new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount("#app");
