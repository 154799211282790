<template>
    <router-view></router-view>
</template>

<style lang="scss">
    // 3rd party plugins css
    @import "~bootstrap-vue/dist/bootstrap-vue.css";
    @import "~perfect-scrollbar/css/perfect-scrollbar.css";
    @import "~socicon/css/socicon.css";
    //@import "~animate.css";
    @import "~@fortawesome/fontawesome-free/css/all.css";
    @import "~line-awesome/dist/line-awesome/css/line-awesome.css";
    @import "assets/plugins/flaticon/flaticon.css";
    @import "assets/plugins/flaticon2/flaticon.css";
    @import "assets/plugins/keenthemes-icons/font/ki.css";
    @import "~vue-snotify/styles/material";

    :root {
        --body-bg-color: #f3f6f9;
        --primary-color:#000;
        --secondary-color: #dfface;
        --primary-text-color:#21251e;
        --secondary-text-color: #ffffff;

        //Login
        --login-bg-color:#1f313d;
        --login-primary-color:#00458f;
        --login-secondary-color:#f2883a;

        //buttons
        --button-bg-color:#00458f;
        --button-primary-color:#38d;
        --button-secondary-color:#16b;
        --button-primary-text-color:#00458f;
        --button-secondary-text-color:#f2883a;


      //Sidebar
        --sidebar-primary-color:#18262e;
        --sidebar-secondary-color: #e0f4c4;
        --sidebar-primary-text-color:#ffffff;
        --sidebar-secondary-text-color: #000000;
        --sidebar-bg-color: #18262e;
        --sidebar-active-menu-bg-color: #18262e;
        --sidebar-active-menu-text-color: #000000;
        --sidebar-menu-text-color: #ffffff;

      //Sub Header
        --header-primary-color:#18262e;
        --header-secondary-color: #e0f4c4 ;
        --header-primary-text-color:#ffffff;
        --header-secondary-text-color: #ffffff;

        --sub-header-bg-color: blue;
        --sub-header-text-color: blue;
        --color-white: #fff;
        --color-dark: #212121;
    }


    // Main demo style scss
    @import "assets/sass/style.vue";

    // Check documentation for RTL css
    // Update HTML with RTL attribute at public/index.html
    /*@import "assets/css/style.vue.rtl";*/
    .vue__time-picker input.display-time{
      padding: 1.5em 0.8em !important;
      width: 100% !important;
      border:1px solid #949191 !important;
    }
    .router-link {
      display: inline-block;
      width: 100px;
    }
</style>

<script>
    import {OVERRIDE_LAYOUT_CONFIG} from "@/core/services/store/config.module";
    export default {
        name: "SCORE-ADMIN",
        mounted() {
            /**
             * this is to override the layout config using saved data from localStorage
             * remove this to use config only from static json (@/core/config/layout.config.json)
             */
            this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
        }
    };
</script>
