import DashboardService from "@/services/dashboard/DashboardService";
const dashboardService=new DashboardService();
const GET_STATS="GET_STATS";
const SET_ACCOUNTHOLDER_COUNT ="SET_ACCOUNTHOLDER_COUNT";
const SET_CANDIDATE_COUNT ="SET_CANDIDATE_COUNT";
const SET_UNLOCK_REQUEST_COUNT ="SET_UNLOCK_REQUEST_COUNT";
const SET_SCHOOL_PAY_APPLICATION_COUNT ="SET_SCHOOL_PAY_APPLICATION_COUNT";
const SET_ASSIGNED_TICKET_COUNT ="SET_ASSIGNED_TICKET_COUNT";
const SET_VENUE_REQUEST_COUNT ="SET_VENUE_REQUEST_COUNT";
const SET_VENUE_UPDATE_REQUEST_COUNT ="SET_VENUE_UPDATE_REQUEST_COUNT";

const state = {
  candidate:0,
  accountHolder:0,
  unlockRequests:0,
  schoolPayApplications:0,
  assignedTickets:0,
  venueRequests: 0,
  venueUpdateRequests: 0,
};
const getters = {
    countCandidate(state) {
        return state.candidate;
    },
    countAccountHolder(state) {
        return state.accountHolder;
    },
    countUnlockRequests(state) {
        return state.unlockRequests;
    },
    countSchoolPayApplications(state) {
        return state.schoolPayApplications;
    },
    countAssignedTicket(state) {
        return state.assignedTickets;
    },
    countVenueRequests(state) {
        return state.venueRequests;
    },
    countVenueUpdateRequests(state) {
        return state.venueUpdateRequests;
    },
};
const actions = {
    /**
     * Set the breadcrumbs list
     * @param state
     * @param payload
     */
    [GET_STATS](context, payload) {
       dashboardService
           .getStats()
           .then(response=>{
                let stats=response.data;
                context.commit(SET_ACCOUNTHOLDER_COUNT,stats.account_holder_update_request_count);
                context.commit(SET_CANDIDATE_COUNT,stats.candidate_update_request_count);
                context.commit(SET_UNLOCK_REQUEST_COUNT,stats.unlock_request_count);
                context.commit(SET_SCHOOL_PAY_APPLICATION_COUNT,stats.school_pay_application_count);
                context.commit(SET_ASSIGNED_TICKET_COUNT,stats.assigned_ticket_count);
                context.commit(SET_VENUE_REQUEST_COUNT,stats.venue_request_count);
                context.commit(SET_VENUE_UPDATE_REQUEST_COUNT,stats.venue_update_request_count);
            }).catch(error=>{
                 context.commit(SET_ACCOUNTHOLDER_COUNT,0);
                 context.commit(SET_CANDIDATE_COUNT,0);
                 context.commit(SET_UNLOCK_REQUEST_COUNT,0);
                 context.commit(SET_SCHOOL_PAY_APPLICATION_COUNT,0);
                 context.commit(SET_ASSIGNED_TICKET_COUNT,0);
                 context.commit(SET_VENUE_REQUEST_COUNT,0);
                 context.commit(SET_VENUE_UPDATE_REQUEST_COUNT,0);
           })
    },

};
const mutations = {
    [SET_ACCOUNTHOLDER_COUNT](state, payload) {
        state.accountHolder = payload;
    },
    [SET_CANDIDATE_COUNT](state, payload) {
        state.candidate = payload;
    },
    [SET_UNLOCK_REQUEST_COUNT](state, payload) {
        state.unlockRequests = payload;
    },
    [SET_SCHOOL_PAY_APPLICATION_COUNT](state, payload) {
        state.schoolPayApplications = payload;
    },
    [SET_ASSIGNED_TICKET_COUNT](state, payload) {
        state.assignedTickets = payload;
    },
    [SET_VENUE_REQUEST_COUNT](state, payload) {
        state.venueRequests = payload;
    },
    [SET_VENUE_UPDATE_REQUEST_COUNT](state, payload) {
        state.venueUpdateRequests = payload;
    },
};
export default {
    state,
    actions,
    mutations,
    getters
};