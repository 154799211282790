import Apiservice from '@/common/api.service';
import { API_URL } from '@/common/config.js';
const apiService = Apiservice;

export default class DashboardService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/dashboard';
    }
    getInfo(filter =null){
        let url = `${this.#api}/${filter}`;
        return apiService.get(url)
    }
    getStats(){
        let url = `${this.#api}/stats`;
        return apiService.get(url);
    }

    getPracticalExamCandidatesCount(data = {}){
        let url = `${this.#api}/practical-exam-candidates/count`;
        
        let param ={
            params: data
        }

        return apiService.query(url, param);
    }

    getOnlineExamCandidatesCount(data = {}){
        let url = `${this.#api}/online-exam-candidates/count`;
        
        let param ={
            params: data
        }

        return apiService.query(url, param);
    }

    getOnlineCourseCandidatesCount(data = {}){
        let url = `${this.#api}/online-course-candidates/count`;

        let param ={
            params: data
        }

        return apiService.query(url, param);
    }

    getAmebAwardCandidatesCount(data = {}){
        let url = `${this.#api}/ameb-award-candidates/count`;

        let param ={
            params: data
        }

        return apiService.query(url, param);
    }

    getUnscheduledCandidatesCount(data = {}){
        let url = `${this.#api}/unscheduled-candidates/count`;

        let param ={
            params: data
        }

        return apiService.query(url, param);
    }

    getEnrolmentStats(data = {}){
        let url = `${this.#api}/enrolment-stats/get`;

        let param ={
            params: data
        }

        return apiService.query(url, param);
    }
    
    getExamDayStats(data = {}){
        let url = `${this.#api}/exam-day-stats/get`;

        let param ={
            params: data
        }

        return apiService.query(url, param);
    }

    getVenueStats(data = {}){
        let url = `${this.#api}/venue-stats/get`;

        let param ={
            params: data
        }

        return apiService.query(url, param);
    }
}