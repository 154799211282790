export const API_URL = process.env.VUE_APP_PROTOCOL;

export const scoreMenus={
    account_holder:{
        all:true,
        create:true,
        update:true,
        delete:false,
    },
    examiner:{
        all:true,
        create:true,
        update:true,
        delete:false,
    },
    score:{
        all:false,
        create:true,
        update:true,
        delete:false,
    },
    enrolment_year:{
        all:false,
        create:false,
        update:false,
        delete:false,
    },
    support_categories:{
        all:false,
        create:false,
        update:false,
        delete:false,
    },
    scheduling_request:{
        all:true,
        create:true,
        update:true,
        delete:true,
    },
    email_template:{
        all:true,
        create:false,
        update:true,
        delete:false,
    },


}
export default {API_URL,scoreMenus};

