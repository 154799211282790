import ApiService from "@/core/services/api.service";
// action types
export const UPDATE_SUPPORT = "updateSupport";
export const UPDATE_GENERAL = "updateGeneral";
export const UPDATE_EXTENSION = "updateExtension";
export const UPDATE_REFUND = "updateRefund";
export const UPDATE_PAYMENT = "updatePayment";
export const UPDATE_APPEAL = "updateAppeal";
export const UPDATE_FEEDBACK = "updateFeedback";
export const UPDATE_SHIPMENT = "updateShipment";
export const UPDATE_OTHER = "updateOther";
export const UPDATE_TOTAL_REFUND = "totalRefund";
export const UPDATE_TOTAL_REFUNDED = "totalRefunded";
export const UPDATE_TOTAL_PENDING = "totalPending";
export const UPDATE_TOTAL_APPROVED = "totalApproved";
export const UPDATE_TOTAL_REJECTED = "totalRejected";

// mutation types
export const SET_SUPPORT = "setSupport";
export const SET_GENERAL = "setGeneral";
export const SET_EXTENSION = "setExtension";
export const SET_REFUND = "setRefund";
export const SET_PAYMENT = "setPayment";
export const SET_APPEAL = "setAppeal";
export const SET_FEEDBACK = "setFeedback";
export const SET_SHIPMENT = "setShipment";
export const SET_OTHER = "setOther";
export const SET_TOTAL_REFUND = "totalRefund";
export const SET_TOTAL_REFUNDED = "setTotalRefunded";
export const SET_TOTAL_PENDING = "setTotalPending";
export const SET_TOTAL_APPROVED = "setTotalApproved";
export const SET_TOTAL_REJECTED = "setTotalRejected";

const state = {
    support: 0,
    general: 0,
    extension: 0,
    refund: 0,
    payment: 0,
    appeal: 0,
    feedback: 0,
    shipment: 0,
    other: 0,
    totalRefund: 0,
    totalRefunded: 0,
    totalPending: 0,
    totalApproved: 0,
    totalRejected: 0,
};

const getters = {
    currentSupport(state) {
        return state.support;
    },
    currentGeneral(state) {
        return state.general;
    },
    currentExtension(state) {
        return state.extension;
    },
    currentRefund(state) {
        return state.refund;
    },
    currentPayment(state) {
        return state.payment;
    },
    currentAppeal(state) {
        return state.appeal;
    },
    currentFeedback(state) {
        return state.feedback;
    },
    currentShipment(state) {
        return state.shipment;
    },
    currentOther(state) {
        return state.other;
    },
    currentTotalRefund(state) {
        return state.totalRefund;
    },
    currentTotalRefunded(state) {
        return state.totalRefunded;
    },
    currentTotalPending(state) {
        return state.totalPending;
    },
    currentTotalApproved(state) {
        return state.totalApproved;
    },
    currentTotalRejected(state) {
        return state.totalRejected;
    },
};

const actions = {
    /**
     * Set the breadcrumbs list
     * @param state
     * @param payload
     */
    [SET_SUPPORT](state) {
        return new Promise(resolve => {

            ApiService.get("admin/support-ticket/get/unread-ticket")
                .then((response) => {
                    state.commit(UPDATE_SUPPORT, response.data.allCount);
                    state.commit(UPDATE_GENERAL, response.data.generalCount);
                    state.commit(UPDATE_EXTENSION, response.data.extensionCount);
                    state.commit(UPDATE_REFUND, response.data.refundCount);
                    state.commit(UPDATE_PAYMENT, response.data.paymentCount);
                    state.commit(UPDATE_APPEAL, response.data.appealCount);
                    state.commit(UPDATE_FEEDBACK, response.data.feedbackCount);
                    state.commit(UPDATE_SHIPMENT, response.data.shipmentCount);
                    state.commit(UPDATE_OTHER, response.data.otherCount);
                    state.commit(UPDATE_TOTAL_REFUND, response.data.totalRefundCount);
                    state.commit(UPDATE_TOTAL_REFUNDED, response.data.totalRefundedCount);
                    state.commit(UPDATE_TOTAL_PENDING, response.data.totalPendingCount);
                    state.commit(UPDATE_TOTAL_APPROVED, response.data.totalApprovedCount);
                    state.commit(UPDATE_TOTAL_REJECTED, response.data.totalRejectedCount);
                    resolve(response);
                })
                .catch(() => {});

        });
    },

    /**
     * Set the set support
     * @param state
     * @param payload
     */
    [SET_GENERAL](state, payload) {
        state.commit(UPDATE_GENERAL, payload);
    },

    /**
     * Set the set support
     * @param state
     * @param payload
     */
    [SET_EXTENSION](state, payload) {
        state.commit(UPDATE_EXTENSION, payload);
    },

    /**
     * Set the set support
     * @param state
     * @param payload
     */
    [SET_REFUND](state, payload) {
        state.commit(UPDATE_REFUND, payload);
    },
    /**
     * Set the set support
     * @param state
     * @param payload
     */
    [SET_PAYMENT](state, payload) {
        state.commit(UPDATE_PAYMENT, payload);
    },
    /**
     * Set the set support
     * @param state
     * @param payload
     */
    [SET_APPEAL](state, payload) {
        state.commit(UPDATE_APPEAL, payload);
    },
    /**
     * Set the set support
     * @param state
     * @param payload
     */
    [SET_FEEDBACK](state, payload) {
        state.commit(UPDATE_FEEDBACK, payload);
    },
    /**
     * Set the set support
     * @param state
     * @param payload
     */
    [SET_SHIPMENT](state, payload) {
        state.commit(UPDATE_SHIPMENT, payload);
    },
    /**
     * Set the set support
     * @param state
     * @param payload
     */
    [SET_OTHER](state, payload) {
        state.commit(UPDATE_OTHER, payload);
    },
    /**
     * Set the set support
     * @param state
     * @param payload
     */
    [SET_TOTAL_REFUND](state, payload) {
        state.commit(UPDATE_TOTAL_REFUND, payload);
    },
    /**
     * Set the set support
     * @param state
     * @param payload
     */
    [SET_TOTAL_REFUNDED](state, payload) {
        state.commit(UPDATE_TOTAL_REFUNDED, payload);
    },
    /**
     * Set the set support
     * @param state
     * @param payload
     */
    [SET_TOTAL_PENDING](state, payload) {
        state.commit(UPDATE_TOTAL_PENDING, payload);
    },
    /**
     * Set the set support
     * @param state
     * @param payload
     */
    [SET_TOTAL_APPROVED](state, payload) {
        state.commit(UPDATE_TOTAL_APPROVED, payload);
    },
    /**
     * Set the set support
     * @param state
     * @param payload
     */
    [SET_TOTAL_REJECTED](state, payload) {
        state.commit(UPDATE_TOTAL_REJECTED, payload);
    },
};

const mutations = {
    [UPDATE_SUPPORT](state, payload) {
        state.support = payload;
    },
    [UPDATE_GENERAL](state, payload) {
        state.general = payload;
    },
    [UPDATE_EXTENSION](state, payload) {
        state.extension = payload;
    },
    [UPDATE_REFUND](state, payload) {
        state.refund = payload;
    },
    [UPDATE_PAYMENT](state, payload) {
        state.payment = payload;
    },
    [UPDATE_APPEAL](state, payload) {
        state.appeal = payload;
    },
    [UPDATE_FEEDBACK](state, payload) {
        state.feedback = payload;
    },
    [UPDATE_SHIPMENT](state, payload) {
        state.shipment = payload;
    },
    [UPDATE_OTHER](state, payload) {
        state.other = payload;
    },
    [UPDATE_TOTAL_REFUND](state, payload) {
        state.totalRefund = payload;
    },
    [UPDATE_TOTAL_REFUNDED](state, payload) {
        state.totalRefunded = payload;
    },
    [UPDATE_TOTAL_PENDING](state, payload) {
        state.totalPending = payload;
    },
    [UPDATE_TOTAL_APPROVED](state, payload) {
        state.totalApproved = payload;
    },
    [UPDATE_TOTAL_REJECTED](state, payload) {
        state.totalRejected = payload;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
