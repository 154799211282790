import {scoreMenus} from "@/core/config";
import Vue from "vue";

 const GeneralMixin = {
     data() {
         return {
             hello: 'hi',
         }
     },
    methods: {
        checkIsAccessible(menu,subMenu='',subSubMenu='') {
           let  user = this.getCurrentUser();
           if(user.access_type=='score')
           {
               if(subSubMenu)
                 return scoreMenus[menu][subMenu][subSubMenu] ? scoreMenus[menu][subMenu][subSubMenu] : false;
               else if(subMenu)
               {
                   return scoreMenus[menu][subMenu] ? scoreMenus[menu][subMenu] : false;
               }

               else
                   return scoreMenus[menu].all;

           }
           else
               return true;

        },

        getCurrentUser() {
            return this.$store.getters.currentUser;
        }
    }
};

export default {
    install (Vue, options) {
        Vue.mixin(GeneralMixin)
    }
}


